import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link for navigation
import '../assets/styles/Sanitar.css'; // Import the CSS file for styling

const Sanitar = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  // Handle when an image is clicked
  const handleShowModal = (images) => {
    setSelectedImages(images);
    setShowModal(true);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImages([]);
  };

  // Data for images in each modal (group of images per modal)
  const imagesGroup1 = [
    '../../images/Wasser1.jpg',
    '../../images/Waster1.jpg',
  ];

  return (
    <div className="sanitar-container">
      {/* Left-aligned back arrow to return to home page */}
      <Link to="/index" className="back-arrow">
        <div className="arrow-icon">←</div>
      </Link>

      <h1>Sanitär</h1>
      <p>
        Wir sind ihr zuverlässiger partner, wenn es um die Planung und Montage von Badezimmer geht.
      </p>

      <div className="image-gallery">
        {/* First image */}
        <img
          src="../../images/Sanitar9.jpg"
          alt="Sanitar Bild 1"
          className="responsive-image"
          onClick={() => handleShowModal(imagesGroup1)} // Open first modal
        />

        {/* Second image */}
        <img
          src="../../images/Sanitar8.jpg"
          alt="Sanitar Bild 2"
          className="responsive-image"
          onClick={() => handleShowModal(imagesGroup1)} // Open second modal
        />
      </div>

      {/* Modal to display the selected images */}
      <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Bilder ansehen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {selectedImages.map((imgSrc, index) => (
              <div key={index} className="col-md-6 col-12">
                <img src={imgSrc} alt={`Bild ${index + 1}`} className="img-fluid" />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sanitar;
